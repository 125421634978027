import { ConsoleSqlOutlined, EllipsisOutlined , PlusOutlined } from '@ant-design/icons' ;      
import type { ActionType , ProColumns } from '@ant-design/pro-components' ;       
import { ProTable , TableDropdown } from '@ant-design/pro-components' ;      
import { Button , Dropdown , Menu , Space , Image, Avatar, Popconfirm } from 'antd' ;         
import { useEffect, useRef, useState } from 'react' ;   
import { useDeleteEventMutation } from 'services/event';
import request from 'umi-request' ;

import { GraphQLClient, gql } from 'graphql-request'
import type { EventResponse, Event } from 'services/event';
import { useAppSelector } from 'store/hooks';
import { selectEvents } from 'state/event/eventSlice';
import { useGetEventsQuery } from 'services/event';
const endpoint = process.env.REACT_APP_API_URL

const client = new GraphQLClient(endpoint, {
  headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}`}
});

const query = gql`
  query GetEventsQluery {
    allEvents {
      id
      title
      resident {
        id
        residentId
        roomNo
      }
      description
      createdAt
      updatedAt
      
    }
  }
`
interface MembershipProps{
  onOpen : () => void
  loading: boolean
}








const menu = ( 
  < Menu >
    < Menu.Item key = " 1 " > 1st item </ Menu.Item > 
    < Menu.Item key = " 2 " > 2nd item </ Menu.Item > 
    < Menu.Item key = " 3 " > 3rd item </ Menu.Item > 
  </ Menu >
) ;

export const EventList = ( { onOpen, loading}: MembershipProps) => {  
    const {data: eventsQuery, refetch } = useGetEventsQuery()
    // console.log("DataEvent: ", eventsQuery)
    const selectedEvents = useAppSelector(selectEvents)
    const [events, setEvents] = useState<Event[]>()
    const [deleteEvent] = useDeleteEventMutation()
  

    const columns : ProColumns < Event > [ ] = [   
      {
        dataIndex : 'index' , 
        valueType : 'indexBorder' , 
        width : 48 , 
      } ,
      {
        title : 'Event Title' , 
        dataIndex : 'title' , 
        // copyable : true , 
        ellipsis : true , 
        tip : 'The title will automatically shrink if it is too long' , 
        formItemProps : { 
          rules : [ 
            {
              required : true , 
              message : 'This item is required' , 
            } ,
          ] ,
        } ,
      } ,
      {
        disable : true , 
        title : 'Description' , 
        dataIndex : 'description' , 
        filters : true , 
        onFilter : true , 
        ellipsis : true , 
        valueType : 'select' , 
        valueEnum : { 
          
        } ,
      } ,
      {
        disable : true , 
        title : 'Photos' , 
        dataIndex : 'photos' , 
        search: false,
        valueType: 'image',
        key: 'photos',
        renderFormItem: ( _, { defaultRender}) => {
          return defaultRender(_)
        },
        render: (_, record) => (
          <Space>
           { record.photos?.map(({ url, name }) => (
             <Avatar src={<Image style={{ width: 52}} key={name} src={`${process.env.REACT_APP_STRAPI_URL}${url}`} />} />
           ))}
          </Space>
        )
        
        
      } ,
      {
        disable : true , 
        title : 'Songs' , 
        dataIndex : 'songs' , 
        filters : true , 
        onFilter : true , 
        ellipsis : true , 
        valueType : 'select' , 
        valueEnum : { 
          all : { text : 'extra long'.repeat ( 50 ) } ,   
          open : { 
            text : 'unresolved' , 
            status : 'Error' , 
          } ,
          closed : { 
            text : 'resolved' , 
            status : 'Success' , 
            disabled : true , 
          } ,
          processing : { 
            text : 'solving' , 
            status : 'Processing' , 
          } ,
        } ,
      } ,
      
      {
        title : 'creation time' , 
        key : 'showTime' , 
        dataIndex : 'createdAt' , 
        valueType : 'dateTime' , 
        sorter : true , 
        hideInSearch : true , 
      } ,
      {
        title : 'Updated time' , 
        dataIndex : 'updatedAt' , 
        valueType : 'dateRange' , 
        hideInTable : true , 
        search : { 
          transform : ( value ) => {   
            return { 
              startTime : value[ 0 ] ,
              endTime : value[ 1 ] ,
            } ;
          } ,
        } ,
      } ,
      {
        title : 'Operation' , 
        valueType : 'option' , 
        key : 'option' , 
        render : ( text , record , _ , action ) => [   
          
          <a
          key="editable"
          onClick = { async ()=>{
            // await deleteEvent(record.id).unwrap()
            // console.log("Record: ", record)
            action?.startEditable?.(record.id);
          }}
          
          >
            Edit
          </a > ,
          <Popconfirm title="Do you want to delete?" onConfirm={async () => await deleteEvent(record.id)}>
            <a
             key="delete"
             onClick = { async ()=>{
            // await deleteEvent(record.id).unwrap()
            // console.log("Record: ", record)
            // action?.startEditable?.(record.id);
            
            }}
          
          >
            Delete
          </a > 
          </Popconfirm>,
         ,
          
        
        ] ,
      } ,
    ] ;
   


    useEffect(() => {
       
       setEvents(selectedEvents)
    }, [selectedEvents])
  
  const actionRef = useRef < ActionType > ( ) ; 
  return (
    < ProTable < Event >
      columns = { columns }
      dataSource={events}
      loading = {loading}
      
      actionRef = { actionRef }
      cardBordered
      
      request = { async ( params = { } , sort , filter ) => {    
        
        const response = await client.request<EventResponse>(query)
        const  result = response.allEvents
        return {
          data: result,
          success: true,
          total: result.length
        }
       
     } }
      editable = { {
        type : 'multiple' , 
      } }
      columnsState = { {
        persistenceKey : 'khan-therapy' , 
        persistenceType : 'localStorage' , 
        onChange ( value ) { 
          console. log ( 'value: ' , value ) ;
        } ,
      } }
      rowKey = "id"
      search = { {
        labelWidth : 'auto' , 
      } }
      options = { {
        setting : { 
          listsHeight : 400 , 
        } ,
      } }
      form = { {
        // Since the transform is configured, the submitted participation is different from the definition and needs to be transformed here
        syncToUrl : ( values, type ) => {   
          if ( type === 'get' ) {   
            return { 
              ... values,
              created_at : [ values.startTime , values.endTime ] , 
            } ;
          }
          return values;
        } ,
      } }
      pagination = { {
        pageSize : 10 , 
        onChange : ( page ) => console.log ( page ) ,   
      } }
      dateFormatter = "string"
      headerTitle = "Residents"
      toolBarRender = { ( ) => [  
        < Button key = " button " icon = { < PlusOutlined /> } type = "primary" onClick={onOpen} >    
          Event
        </ Button > ,
        < Dropdown key = " menu " overlay = { menu } >  
          < Button >
            < EllipsisOutlined /> 
          </ Button >
        </ Dropdown > ,
      ] }
    / >
  ) ;
} ;