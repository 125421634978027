import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  extendTheme,
} from "@chakra-ui/react";
import "@fontsource/inter/variable.css";
import "focus-visible/dist/focus-visible";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import "antd/dist/antd.min.css";
import "@ant-design/pro-components/dist/components.css";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { store } from "store";
import { AuthProvider } from "containers/Auth";
import { theme as baseTheme } from "@chakra-ui/pro-theme";

import * as components from "./components";
import * as foundations from "./foundations";

export const theme: Record<string, any> = extendTheme({
  ...foundations,
  components: { ...components },
  colors: { ...baseTheme.colors, brand: baseTheme.colors.blue },
  space: {
    "4.5": "1.125rem",
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <AuthProvider>
          <ConfigProvider locale={enUS}>
            <App />
          </ConfigProvider>
        </AuthProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
