import {
  Avatar,
  AvatarGroup,
  Box,
  Center,
  DarkMode,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  extendTheme,
  useBreakpointValue,
  useColorModeValue as mode,
  ChakraProvider,
} from "@chakra-ui/react";

import "@fontsource/inter/variable.css";
import "focus-visible/dist/focus-visible";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import "antd/dist/antd.min.css";
import "@ant-design/pro-components/dist/components.css";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./signin.css";
import { SignInForm } from "./SignInForm";
import { theme as baseTheme } from "@chakra-ui/pro-theme";

import * as components from "components";
import * as foundations from "foundations";

export const theme: Record<string, any> = extendTheme({
  ...foundations,
  components: { ...components },
  colors: { ...baseTheme.colors, brand: baseTheme.colors.blue },
  space: {
    "4.5": "1.125rem",
  },
});


const SigninAuth = () => {
  return (
    <Flex
      minH={{ base: "auto", md: "100vh" }}
      bgGradient={useBreakpointValue({
        md: mode(
          "linear(to-r, blue.600 50%, white 50%)",
          "linear(to-r, blue.600 50%, gray.900 50%)"
        ),
      })}
    >
      <Flex maxW="8xl" mx="auto" width="full">
        <Box flex="1" display={{ base: "none", md: "block" }}>
          <DarkMode>
            <Flex
              direction="column"
              px={{ base: "4", md: "8" }}
              height="full"
              color="on-accent"
            >
              <Flex align="center" h="24">
                {/* <Logo /> */}
              </Flex>
              <Flex flex="1" align="center">
                <Stack spacing="8">
                  <Stack spacing="6">
                    <Heading size={useBreakpointValue({ md: "lg", xl: "xl" })}>
                      Reminiscence Therapy for Elderly Care
                    </Heading>
                    <Text fontSize="lg" maxW="md" fontWeight="medium">
                      Login to administer the therapy
                    </Text>
                  </Stack>
                  <HStack spacing="4">
                    <AvatarGroup
                      size="md"
                      max={useBreakpointValue({ base: 2, lg: 5 })}
                      borderColor="on-accent"
                    >
                      <Avatar
                        name="Ryan Florence"
                        src="https://bit.ly/ryan-florence"
                      />
                      <Avatar
                        name="Segun Adebayo"
                        src="https://bit.ly/sage-adebayo"
                      />
                      <Avatar
                        name="Kent Dodds"
                        src="https://bit.ly/kent-c-dodds"
                      />
                      <Avatar
                        name="Prosper Otemuyiwa"
                        src="https://bit.ly/prosper-baba"
                      />
                      <Avatar
                        name="Christian Nwamba"
                        src="https://bit.ly/code-beast"
                      />
                    </AvatarGroup>
                  </HStack>
                </Stack>
              </Flex>
              <Flex align="center" h="24">
                <Text color="on-accent-subtle" fontSize="sm">
                  © 2022 DECRS. All rights reserved.
                </Text>
              </Flex>
            </Flex>
          </DarkMode>
        </Box>
        <Center flex="1">
          <SignInForm
            px={{ base: "4", md: "8" }}
            py={{ base: "12", md: "48" }}
            width="full"
            maxW="md"
          />
        </Center>
      </Flex>
    </Flex>
  );
};

export const Signin = () => {
  return (
    <ChakraProvider theme={theme}>
      <SigninAuth />
    </ChakraProvider>
  );
};
